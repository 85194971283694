* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Lato', sans-serif; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans','Droid Sans', 'Helvetica Neue', sans-serif; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; */
  font-family: 'Lato', sans-serif;
}

a {
  color: black;
  text-decoration: none;
}

li {
  list-style: none;
}
