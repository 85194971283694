.whatsappButton-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 50px;
  background-color: #25d366;
  border-radius: 10px;
  // padding: 40px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;

  @media screen and (max-width: 900px) {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    bottom: 30px;
    right: 30px;
  }

  &:hover {
    background-color: #1b9648;
  }

  i {
    font-size: 15px;
    color: white;
    font-style: normal;

    @media screen and (max-width: 900px) {
      font-size: 25px;
    }
  }

  p {
    display: inline;
    margin-left: 5px;
    letter-spacing: 1px;

    @media screen and (max-width: 900px) {
      display: none;
    }
  }
}
