.navbar {
  // height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 0.5rem 3rem;
  border-bottom: 2px solid #e85a0c;

  @media screen and (max-width: 900px) {
    justify-content: space-between;
    padding: 1rem 2rem;
    border-bottom: 1px solid #e85a0c;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
  }

  .nav-mobile-menu {
    display: none;
    cursor: pointer;

    @media screen and (max-width: 900px) {
      display: block;
    }

    i {
      font-size: 2rem;
    }
  }

  .nav-logo-container {
    width: 90px;

    @media screen and (max-width: 900px) {
      width: 70px;
    }

    img {
      width: 100%;
    }
  }

  .nav-links {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    @media screen and (max-width: 900px) {
      display: none;
    }

    .selected-nav-link {
      border-bottom-color: red;
    }

    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 1.1rem;

      li,
      .react-nav-links {
        margin: 0 2rem;
        padding: 0.5rem;
        display: flex;
        justify-content: center;
        border-bottom: 4px transparent solid;
        transform-origin: left center;
        transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;

        &:hover {
          border-bottom: 2px #e85a0c solid;
          transform: scaleX(1);
          color: #e85a0c;
        }

        i {
          color: #e85a0c;
          margin-right: 0.5rem;
          font-size: 1.2rem;
        }
      }
    }
  }

  .nav-shopping-cart {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // border: 1px solid #e85a0c;
    // border-radius: 50%;
    // background-color: black;
    color: black;
    position: relative;

    .cart-badge-quantity {
      position: absolute;
      bottom: 20px;
      left: 20px;
      background-color: black;
      color: white;
      width: 20px;
      height: 20px;
      padding: 0.3rem 0.5rem;
      border-radius: 50%;
      font-size: 0.8rem;
      font-weight: bold;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      @media screen and (max-width: 900px) {
        font-size: 0.8rem;
        // padding: 0.15rem 0.3rem;
        // width: 25px;
        // height: 25px;
      }
    }

    i {
      // color: #e85a0c;
      font-size: 1.5rem;
      margin-right: 0.5rem;
      color: #e85a0c;
      transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;

      &:hover {
        transform: scale(1);
        color: white;
        background-color: #e85a0c;
        padding: 0.6rem 0.8rem;
        border-radius: 100%;
      }
    }

    .cart-quantity {
      // color: #e85a0c;
      // margin-left: 0.2rem;
      font-style: normal;
      font-weight: bolder;
    }
  }
}
