.shopping-cart-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25vw;
  padding: 1rem;
  // height: 100%;
  border-left: 2px solid #e85a0c;
  // border-bottom: 5px solid #e85a0c;

  @media screen and (max-width: 900px) {
    width: 80vw;
  }

  .shopping-cart-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #e85a0c;
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    h1 {
      // margin: 2rem;
      // border: 1px solid black;

      i {
        margin-right: 0.5rem;
      }

      @media screen and (max-width: 900px) {
        font-size: 1.2rem;
      }
    }

    button {
      background: none;
      border: none;
      // border: 1px solid black;

      i {
        font-size: 1.5rem;

        @media screen and (max-width: 900px) {
          font-size: 1rem;
        }

        &:hover {
          color: #e85a0c;
          cursor: pointer;
        }
      }
    }
  }
}
