.payment-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: white;
  width: 30vw;
  margin: 2rem auto;
  top: 50%;
  padding: 1.5rem;
  border-radius: 1rem;
  border: 1px #e85a0c solid;

  @media screen and (max-width: 900px) {
    width: 70vw;
    padding: 1rem;
    margin: 20% auto;
    // border: none;
  }

  img {
    width: 25%;

    @media screen and (max-width: 900px) {
      width: 15%;
      margin-top: 1rem;
    }
  }

  .checkout-header {
    color: black;
    text-align: center;
    font-size: 2rem;
    margin: 1.5rem 0;
    border-bottom: 2px #e85a0c solid;
    width: 100%;

    @media screen and (max-width: 900px) {
      font-size: 1rem;
      margin: 0.5rem 0;
      text-align: center;
    }
  }

  .payment-divider {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  .product-billing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: black;
    // height: 100%;
    padding: 2rem;

    @media screen and (max-width: 900px) {
      padding: 0.5rem;
      text-align: center;
    }

    h3 {
      // align-self: flex-start;

      @media screen and (max-width: 900px) {
        font-size: 0.8rem;
      }
    }

    .payment-form {
      display: flex;
      flex-direction: column;
      // padding: 2rem;
      width: 100%;

      label {
        font-size: 1rem;
        margin-bottom: 0.2rem;

        @media screen and (max-width: 900px) {
          align-self: flex-start;
          font-size: 10px !important;
          margin-top: 1rem;
        }
      }

      input {
        padding: 1rem 4rem 1rem 0.2rem;
        border-radius: 0rem;
        border: 1px solid #e85a0c;
        margin-bottom: 1rem;
        width: 100%;

        @media screen and (max-width: 900px) {
          border: none;
          border-bottom: 0.5px solid #e85a0c;
          font-size: 16px !important;
          overflow: hidden;
          width: 100%;
          padding: 0;
          padding-bottom: 0.25rem;
        }
      }
    }

    .payment-total {
      color: black;
      font-size: 1.5rem;
      font-weight: bolder;

      @media screen and (max-width: 900px) {
        font-size: 0.8rem;
        // color: #e85a0c;
      }
    }

    .momo-logo {
      margin-top: 1rem;
      width: 50%;
    }

    .payment-fine-print {
      font-size: 0.75rem;
      text-align: center;
      margin-top: 1rem;

      @media screen and (max-width: 900px) {
        font-size: 0.5rem;
      }
    }
  }

  .paystack-button {
    cursor: pointer;
    text-align: center;
    font-size: 1rem;
    font-weight: bolder;
    // letter-spacing: 0.1rem;
    text-transform: uppercase;
    background-color: #e85a0c;
    font-weight: bold;
    color: white;
    border: 1px solid #e85a0c;
    border-radius: 5px;
    width: 100%;
    padding: 1rem 1rem;
    margin-top: 1rem;
    transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;

    &:hover {
      background-color: black;
      color: #e85a0c;
      border: 1px solid #e85a0c;
    }

    @media screen and (max-width: 900px) {
      font-size: 0.8rem;
      padding: 0.5rem;
    }
  }
}
