.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  padding: 0rem 1rem;
  background-color: rgb(8, 8, 8);
  color: white;
  border-top: 2px solid #e85a0c;
  width: 100%;
  height: fit-content;

  .footer-details-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    margin-bottom: 20px;
    padding: 0.5rem;
    width: 100%;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      align-content: center;
      justify-content: center;
      padding: 0.5rem;
    }
  }

  .footer-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    @media screen and (max-width: 900px) {
      align-items: center;
      text-align: center;
      margin-bottom: 50px;
    }

    .h2-tagline {
      font-size: 1rem;
      font-weight: bolder;
      text-align: center;
      margin-top: 20px;
      color: #e85a0c;
    }

    .footer-logo-container {
      width: 125px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 900px) {
        width: 100px;
      }

      img {
        width: 100%;
      }
    }

    .footer-tagline {
      margin: 2rem 0;
      text-align: center;
      width: 80%;

      h2 {
        font-size: 1rem;
        font-weight: bolder;
        text-align: center;
        @media screen and (max-width: 900px) {
          text-align: center;
        }
      }
    }

    p {
      font-size: 0.8rem;
      font-weight: 400;
      text-align: center;
      width: 80%;
      line-height: 20px;
      @media screen and (max-width: 900px) {
        text-align: center;
      }
    }
  }

  .footer-middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    @media screen and (max-width: 900px) {
      margin-bottom: 50px;
    }

    h2 {
      margin-top: 50px;
      font-size: 1rem;
      font-weight: bolder;
      color: #e85a0c;
    }

    .footer-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      height: 80px;
      width: 100%;
      margin-top: 20px;
      text-align: center;

      i {
        font-size: 1.4rem;
        color: #e85a0c;
      }

      h3 {
        font-size: 0.9rem;
        font-weight: bolder;
      }

      h4 {
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .footer-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
    line-height: 1.3;
    flex: 1;

    h2 {
      margin-top: 20px;
      font-size: 1rem;
      font-weight: bolder;
      color: #e85a0c;
    }

    @media screen and (max-width: 900px) {
      padding: 0rem;
    }

    p {
      @media screen and (max-width: 900px) {
        font-size: 0.8rem;
      }
    }

    .footer-icons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 80%;

      @media screen and (max-width: 900px) {
        align-items: center;
        margin-top: 30px;
      }

      .footer-icons-div {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        a {
          color: lightgray;
          text-decoration: underline;
          transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
          font-size: 0.9rem;
          font-weight: bolder;

          @media screen and (max-width: 900px) {
            font-size: 1rem;
          }

          &:hover {
            color: white;
          }
        }

        @media screen and (max-width: 900px) {
          font-size: 0.8rem;
        }
      }
      i {
        font-style: normal;
        margin-bottom: 2px;
        color: #e85a0c;
        font-size: 1.2rem;
      }
    }
  }

  .copyright-footer {
    border-top: 1px solid rgb(20, 20, 20);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 100%;
    text-align: center;
    padding: 2rem;
    font-size: 12px;
    margin-top: 30px;

    @media screen and (max-width: 900px) {
      font-size: 8px;
    }

    a {
      color: #e85a0c;
      text-decoration: underline;
    }
  }
}
