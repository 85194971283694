.please-add-to-cart {
  @media screen and (max-width: 900px) {
    font-size: 0.9rem;
  }
}

.checkout-preview-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .checkout-preview-button {
    width: 100%;
    padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: normal;
    background-color: #e85a0c;
    color: white;
    border: 1px solid #e85a0c;
    font-weight: bolder;
    margin-bottom: 4rem;

    @media screen and (max-width: 900px) {
      font-size: 0.8rem;
      padding: 0.7rem 1.2rem;
    }

    border-radius: 0.5rem;
    transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    cursor: pointer;

    &:hover {
      background-color: black;
      color: #e85a0c;
      border: 1px solid #e85a0c;
    }
  }
}

.checkout-total {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0.1rem;
  border-top: 1px solid #e85a0c;

  .total {
    font-size: 1.5rem;
    color: grey;

    @media screen and (max-width: 900px) {
      font-size: 0.9rem;
    }
  }

  .price {
    font-size: 1.8rem;
    font-weight: 400;
    color: #e85a0c;

    @media screen and (max-width: 900px) {
      font-size: 1.2rem;
      font-weight: bolder;
    }
  }
}
