.checkout-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 100px;
  padding: 15px 0;
  font-size: 16px;
  border-bottom: 1px solid lightgray;

  .image-container {
    width: 100px;
    padding-right: 15px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .checkout-product-info {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .product-name {
      font-weight: bolder;

      @media screen and (max-width: 900px) {
        font-size: 0.8rem;
      }
    }

    .product-description {
      margin-top: 0.2rem;
      font-size: 12px;

      @media screen and (max-width: 900px) {
        font-size: 0.7rem;
      }
    }

    .checkout-product-quantity {
      margin-top: 1rem;
      .minus {
        border: 2px solid lightgray;
        color: black;
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
        text-align: center;
        padding: 3px;
        cursor: pointer;

        &:hover {
          background-color: #e85a0c;
          color: white;
          border-color: white;
        }
      }
      .number {
        border: 2px solid lightgray;
        color: black;
        text-align: center;
        padding: 3px 8px;
      }

      .plus {
        border: 2px solid lightgray;
        color: black;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        text-align: center;
        padding: 3px;
        cursor: pointer;

        &:hover {
          background-color: #e85a0c;
          color: white;
          border-color: white;
        }
      }
    }

    .checkout-product-price {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;

      .quantity {
        color: grey;
        font-size: 1rem;
        letter-spacing: 0.2rem;
      }

      .price {
        color: #e85a0c;
        // font-size: 1.5rem;
        margin-left: 0.5rem;
        font-weight: bolder;
      }
    }
  }

  .checkout-product-clear-from-cart {
    color: red;
    cursor: pointer;
  }
}
