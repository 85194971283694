.mobile-nav-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 20vw;
  height: 100%;
  padding: 1rem;
  border-right: 2px solid #e85a0c;
  background-color: black;
  color: white;

  h1 {
    @media screen and (max-width: 900px) {
      font-size: 2rem;
      color: #e85a0c;
      padding: 0.5rem;
    }
  }

  @media screen and (max-width: 900px) {
    width: 80vw;
  }

  .mobile-nav-links {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    margin-top: 2rem;
    width: 100%;

    ul {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.1rem;
      width: 100%;

      @media screen and (max-width: 900px) {
        font-size: 0.8rem;
      }

      li {
        margin: 1.25rem 0;
        padding: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 4px transparent solid;
        transform-origin: left center;
        transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
        width: 100%;
        cursor: pointer;

        &:hover {
          color: #e85a0c;
        }

        i {
          color: #e85a0c;
          display: flex;
          flex-direction: row;
          margin-right: 0.5rem;
          font-size: 1rem;
          font-style: normal;

          p {
            margin-left: 0.5rem;
            font-size: 1rem;
            color: white;
          }

          @media screen and (max-width: 900px) {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
